import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  themes = new BehaviorSubject<string>("light");
  theme$ = this.themes.asObservable();

  constructor() {}

  setTheme(theme = "light") {
    this.themes.next(theme);
  }
}
